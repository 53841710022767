import React from 'react';
import { Linkedin, MailIcon, ChevronRightIcon, CodeIcon, LayoutIcon, AwardIcon, GlobeIcon, ShieldIcon, RocketIcon, BrainCircuitIcon } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface Project {
  title: string;
  description: string;
  impact: string;
  tech: string[];
  category: 'mobile' | 'backend' | 'fullstack';
}

interface Service {
  title: string;
  description: string;
  icon: React.ReactNode;
  details: string[];
}

const Portfolio = () => {
    const projects: Project[] = [
        {
          title: 'Enterprise Payment Platform',
          description: 'Built a secure payment and escrow platform enabling small businesses to manage transactions safely across emerging markets',
          impact: '',
          tech: ['Go', 'React', 'TypeScript', 'Vite', 'shadcn/ui', 'gorm', 'gorilla/mux', 'PostgreSQL', 'Docker'],
          category: 'fullstack'
        },
        {
          title: 'AI-Powered Content Platform',
          description: 'Developed an content recommendation system using LLMs and vector search for personalized content discovery',
          impact: '',
          tech: ['Astro', 'LangChain', 'Pinecone', 'OpenAI', 'TypeScript', 'Docker', 'AWS'],
          category: 'fullstack'
        },
        {
          title: 'Fintech Money Transfer Platform',
          description: 'Led development of a cross-border money transfer platform and anti-money laundering system',
          impact: '',
          tech: ['Kotlin', 'Retrofit', 'Java', 'Docker', 'MySQL'],
          category: 'fullstack'
        },
        {
          title: 'Social Commerce Video Platform',
          description: 'Built a TikTok-style video feed feature for a European e-commerce platform',
          impact: '',
          tech: ['Android', 'Kotlin', 'Jetpack Compose', 'GraphQL', 'ExoPlayer', 'Mux', 'Pubnub'],
          category: 'mobile'
        },
        {
          title: 'Luxury E-commerce App',
          description: "Native iOS shopping application for high-end fashion retailer",
          impact: '',
          tech: ['iOS', 'SwiftUI', 'Firebase', 'Fastlane'],
          category: 'mobile'
        },
        {
          title: 'Financial Services SDK',
          description: 'Authentication SDK enabling secure access across banking applications',
          impact: '',
          tech: ['Android', 'iOS', 'Security', 'Fastlane', 'Groovy'],
          category: 'mobile'
        }
      ];

  const services: Service[] = [
    {
      title: 'Payment Systems',
      description: 'Secure payment infrastructure with escrow capabilities for emerging markets',
      icon: <ShieldIcon className="w-8 h-8" />,
      details: [
        'Escrow Payment Processing',
        'Fraud Prevention',
        'Multi-currency Support',
        'Compliance & Security'
      ]
    },
    {
      title: 'Mobile App Development',
      description: 'Native iOS and Android applications built with modern frameworks and best practices',
      icon: <GlobeIcon className="w-8 h-8" />,
      details: ['Native Android (Kotlin/Java)', 'Native iOS (Swift)', 'Mobile DevOps', 'UI/UX Implementation']
    },
    {
      title: 'SDK Development',
      description: 'Custom SDKs that enable seamless integration of complex functionality',
      icon: <CodeIcon className="w-8 h-8" />,
      details: ['Authentication Systems', 'Payment Integration', 'Analytics', '3rd Party SDKs']
    },
    {
      title: 'Backend Development',
      description: 'Scalable and secure backend services that power modern applications',
      icon: <LayoutIcon className="w-8 h-8" />,
      details: ['API Development', 'Database Design', 'Cloud Infrastructure', 'Security Implementation']
    },
    {
        title: 'Product Development',
        description: 'End-to-end product development from ideation to market launch',
        icon: <RocketIcon className="w-8 h-8" />,
        details: [
          'Market Research & Analysis',
          'MVP Development',
          'Product Strategy',
          'User Testing & Iteration'
        ]
      },
      {
        title: 'Tech Strategy',
        description: 'Strategic technology planning and implementation for business growth',
        icon: <BrainCircuitIcon className="w-8 h-8" />,
        details: [
          'Technology Roadmapping',
          'Architecture Planning',
          'Team Structure & Growth',
          'Cost Optimization'
        ]
      },
  ];

  return (
    <div className="min-h-screen bg-background">
      <header className="relative bg-background">
        <div className="max-w-6xl mx-auto px-4 py-24">
          <div className="space-y-6">
            <h1 className="text-5xl font-bold">Brian Machimbira</h1>
            <p className="text-2xl text-muted-foreground max-w-3xl">
              Full Stack Engineer specializing in secure payment systems, AI solutions, and scalable applications
            </p>
            <p className="text-lg text-muted-foreground max-w-3xl">
              14+ years building robust applications, payment platforms, and authentication systems. Expert in Native Android and iOS, Go, React, and cloud infrastructure.
            </p>
            <div className="flex gap-4 pt-4">
              
              <Button variant="outline" size="icon" asChild>
                <a href="https://www.linkedin.com/in/brianmachimbira/" target="_blank" rel="noopener noreferrer">
                  <Linkedin className="w-5 h-5" />
                </a>
              </Button>
              <Button variant="outline" size="icon" asChild>
                <a href="mailto:brian@brianmachimbira.dev">
                  <MailIcon className="w-5 h-5" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </header>

      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12">Services</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <Card key={service.title}>
                <CardHeader>
                  <div className="text-primary mb-4">
                    {service.icon}
                  </div>
                  <CardTitle>{service.title}</CardTitle>
                  <CardDescription>{service.description}</CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {service.details.map(detail => (
                      <li key={detail} className="flex items-center text-muted-foreground">
                        <ChevronRightIcon className="w-4 h-4 text-primary mr-2" />
                        {detail}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-muted/50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8">Featured Projects</h2>
          <Tabs defaultValue="all">
            <TabsList>
              <TabsTrigger value="all">All</TabsTrigger>
              <TabsTrigger value="fullstack">Full Stack</TabsTrigger>
              <TabsTrigger value="mobile">Mobile</TabsTrigger>
            </TabsList>
            <TabsContent value="all" className="mt-6">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {projects.map(project => (
                  <Card key={project.title}>
                    <CardHeader>
                      <CardTitle>{project.title}</CardTitle>
                      <CardDescription>{project.description}</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p className="text-green-600 dark:text-green-400 text-sm mb-4">{project.impact}</p>
                      <div className="flex flex-wrap gap-2">
                        {project.tech.map(tech => (
                          <Badge key={tech} variant="secondary">{tech}</Badge>
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </TabsContent>
            {['fullstack', 'backend', 'mobile'].map(category => (
              <TabsContent key={category} value={category} className="mt-6">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {projects
                    .filter(p => p.category === category)
                    .map(project => (
                      <Card key={project.title}>
                        <CardHeader>
                          <CardTitle>{project.title}</CardTitle>
                          <CardDescription>{project.description}</CardDescription>
                        </CardHeader>
                        <CardContent>
                          <p className="text-green-600 dark:text-green-400 text-sm mb-4">{project.impact}</p>
                          <div className="flex flex-wrap gap-2">
                            {project.tech.map(tech => (
                              <Badge key={tech} variant="secondary">{tech}</Badge>
                            ))}
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </section>

      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12">Key Achievements</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              'Built an escrow payment platform processing secure transactions across Southern Africa',
              'Led development of mobile SDKs used by companies across Africa and Europe',
              'Architected authentication systems securing millions of user accounts',
              'Developed e-commerce platforms processing $10M+ in annual transactions',
              'Mentored junior developers and led technical teams across multiple projects',
              'Increased user engagement by 20% by building a social commerce video platform',
            ].map((achievement, index) => (
              <Card key={index}>
                <CardContent className="flex items-start space-x-4 pt-6">
                  <div className="bg-primary/10 p-2 rounded-lg">
                    <AwardIcon className="w-6 h-6 text-primary" />
                  </div>
                  <p className="text-lg">{achievement}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-muted/50">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Let's Build Something Great</h2>
          <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
            Looking to build a secure, scalable application? Let's discuss how I can help bring your vision to life.
          </p>
          <Button size="lg" asChild>
            <a href="mailto:brian@brianmachimbira.dev">Get in Touch</a>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;